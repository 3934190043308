<script setup>
import { ArrowsPointingOutIcon, PhotoIcon } from "@heroicons/vue/24/outline";
import { reactive } from "vue";

const props = defineProps({
    url: {
        type: String,
        required: true,
        default: ''
    },
    style: {
        type: Object,
        default: {}
    }
})

const hasImages = props.url?.includes('.') // .jpg

const styleObject = reactive({
    backgroundImage: hasImages ? `url(${props.url})` : '',
    minHeight: '8rem',
    ...props.style
})
</script>

<template>
    <div :class="`h-100 w-100 relative rounded overflow-hidden bg-cover ${!hasImages ? 'bg-gray-200' : ''}`"
        :style="styleObject">
        <div class="position-absolute w-100 h-100" v-if="!hasImages">
            <PhotoIcon
                class="w-16 h-16 text-gray-500 position-absolute top-50 start-50 translate-middle rounded-1 p-2 lh-1" />
        </div>
        <!-- Card hover element -->
        <div class="hover-element position-absolute w-100 h-100" v-if="hasImages">
            <ArrowsPointingOutIcon
                class="w-8 h-8 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1" />
        </div>
    </div>
</template>
